import React, { useState } from "react"
import styled from "styled-components"
import Link from "next/link"
import { colors } from "../../styles/colors"
import { useGlobalState } from "../../context/GlobalContextProvider"
import { isSSR } from "lib/utils"
import { Flex } from "components/layout/shared"
import { FauxSearch } from "components/custom-header"

const Header = ({ onClick }) => {
  const state = useGlobalState()
  // const pool = state?.currentStoreDetails?.pool ?? 'prod' //Hide Cart from stores that aren't part of our checkout testing.
  return (
    <Wrapper>
      <Nav>
        <Link href="/">
          <LogoImg src={"/unqueuewordmarklogo.png"} />
        </Link>
        <Flex align="center" direction="row">
          {onClick && <FauxSearch onClick={onClick} />}
          <Cart>
            {!isSSR && (
              <Link href="/cart">
                <a>Cart ({state.shoppingCart.length})</a>
              </Link>
            )}
          </Cart>
        </Flex>
      </Nav>
    </Wrapper>
  )
}

export default Header

const Wrapper = styled.div`
  background: ${colors.base};
  position: sticky;
  top: 0;
  z-index: 99;
`
const Nav = styled.nav`
  max-width: 1200px;
  margin: 0 auto;
  padding: 15px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 530px) {
    padding: 15px;
  }
`

const LogoImg = styled.img`
  height: 21px;
  cursor: pointer;

  @media (max-width: 530px) {
    height: auto;
    max-width: 80px;
  }
`

const Cart = styled.div`
  display: block;
  align-self: center;
  justify-self: flex-end;
  margin-right: 20px;
  font-size: 20px;
  color: #fff;

  > a {
    color: #fff;
  }
`
